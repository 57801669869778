const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.ozminerals.forwoodsafety.com',
    APP_CLIENT_ID: '1ntbm7lgcpld193a8n73i0j0am',
    APIGATEWAY: 'https://ylidoden84.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://5jvut6h276.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'staging',
    VERSION: 'resources-container-1.7.0',
    HOSTNAME: 'https://resources.staging.ozminerals.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.staging.ozminerals.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.staging.ozminerals.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.staging.ozminerals.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.staging.ozminerals.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
